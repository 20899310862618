import { createRouter, createWebHashHistory,createWebHistory } from "vue-router";
//import IndustryTrainingRoutes from './utils/Router/IndustryTraining';
const importPage = (path) => import("@/pages/" + path);
const importLayout = (path) => import("@/layouts/" + path);

// 路由表
const routes = [
  {
    path: "/",
    redirect: "/BeforeExam/index",
  },
  {
    path: "/BeforeExam",
    redirect: "/BeforeExam/index",
  },
  // ===== 系统首页 =====
  {
    path: "/BeforeExam/index",
    component: () => importPage("BeforeExam/index"),
    meta: { title: "咨询工程师考前培训-兰麦咨询工程师考前辅导-北京兰麦教育" },
  },
  // ===== 手机号码登录 =====
  {
    path: "/BeforeExam/loginPhone",
    component: () => importPage("BeforeExam/loginPhone"),
    meta: { title: "咨询工程师考前培训-兰麦咨询工程师考前辅导-北京兰麦教育-手机号码登录" },
  },
   // ===== 手机号码登录 =====
   {
    path: "/BeforeExam/loginCode",
    component: () => importPage("BeforeExam/loginCode"),
    meta: { title: "咨询工程师考前培训-兰麦咨询工程师考前辅导-北京兰麦教育-手机号码登录" },
  },
   // ===== 微信扫码登录 =====
   {
    path: "/BeforeExam/loginScan",
    component: () => importPage("BeforeExam/loginScan"),
    meta: { title: "咨询工程师考前培训-兰麦咨询工程师考前辅导-北京兰麦教育-微信扫码登录" },
  },
  // ===== 注册 =====
  {
    path: "/BeforeExam/register",
    component: () => importPage("BeforeExam/register"),
    meta: { title: "咨询工程师考前培训-兰麦咨询工程师考前辅导-北京兰麦教育-注册" },
  },
  // ===== 注册 =====
    {
      path: "/BeforeExam/retrievePassword",
      component: () => importPage("BeforeExam/retrievePassword"),
      meta: { title: "咨询工程师考前培训-兰麦咨询工程师考前辅导-北京兰麦教育-忘记密码" },
    },
  // ===== 个人中心 =====
  {
    path: "/BeforeExam/personalCenter",
    component: () => importPage("BeforeExam/personalCenter"),
    meta: { title: "咨询工程师考前培训-兰麦咨询工程师考前辅导-北京兰麦教育-个人中心" },
  },
  // ===== 个人信息 =====
  {
    path: "/BeforeExam/personalInfo",
    component: () => importPage("BeforeExam/personalInfo"),
    meta: { title: "咨询工程师考前培训-兰麦咨询工程师考前辅导-北京兰麦教育-个人信息" },
  },
  // ===== 我的课程 =====
  {
    path: "/BeforeExam/memberCourse",
    component: () => importPage("BeforeExam/memberCourse"),
    meta: { title: "咨询工程师考前培训-兰麦咨询工程师考前辅导-北京兰麦教育-我的课程" },
  },
  {
    path: "/BeforeExam/exambookVideo",
    component: () => importPage("BeforeExam/exambookVideo"),
    meta: { title: "咨询工程师考前培训-兰麦咨询工程师考前辅导-北京兰麦教育-我的考试用书视频" },
  },
  {
    path: "/BeforeExam/memberOrder",
    component: () => importPage("BeforeExam/memberOrder"),
    meta: { title: "咨询工程师考前培训-兰麦咨询工程师考前辅导-北京兰麦教育-我的订单" },
  },
  {
    path: "/BeforeExam/memberOrder_new",
    component: () => importPage("BeforeExam/memberOrder_new"),
    meta: { title: "咨询工程师考前培训-兰麦咨询工程师考前辅导-北京兰麦教育-我的订单" },
  },
  {
    path: "/BeforeExam/memberInvoiceApply",
    component: () => importPage("BeforeExam/memberInvoiceApply"),
    meta: { title: "咨询工程师考前培训-兰麦咨询工程师考前辅导-北京兰麦教育-我的发票" },
  },
  // =====考试用书及视频 =====
  {
    path: "/BeforeExam/examBook",
    component: () => importPage("BeforeExam/examBook"),
    meta: { title: "咨询工程师考前培训-兰麦咨询工程师考前辅导-北京兰麦教育-考试用书及视频" },
  },
  {
    path: "/BeforeExam/examBookInfo",
    component: () => importPage("BeforeExam/examBookInfo"),
    meta: { title: "咨询工程师考前培训-兰麦咨询工程师考前辅导-北京兰麦教育-考试用书及视频-详情" },
  },
  {
    path: "/BeforeExam/examBookAudition",
    component: () => importPage("BeforeExam/examBookAudition"),
    meta: { title: "咨询工程师考前培训-兰麦咨询工程师考前辅导-北京兰麦教育-考试用书及视频-试听" },
  },
  {
    path: "/BeforeExam/viewingProcess",
    component: () => importPage("BeforeExam/viewingProcess"),
    meta: { title: "咨询工程师考前培训-兰麦咨询工程师考前辅导-北京兰麦教育-看课流程" },
  },
  // =====全部课程 =====
  {
    path: "/BeforeExam/curriculum",
    component: () => importPage("BeforeExam/curriculum"),
    meta: { title: "咨询工程师考前培训-兰麦咨询工程师考前辅导-北京兰麦教育-全部课程" },
  },
  {
    path: "/BeforeExam/curriculumInfo",
    component: () => importPage("BeforeExam/curriculumInfo"),
    meta: { title: "咨询工程师考前培训-兰麦咨询工程师考前辅导-北京兰麦教育-全部课程-详情" },
  },
  {
    path: "/BeforeExam/curriculumAudition",
    component: () => importPage("BeforeExam/curriculumAudition"),
    meta: { title: "咨询工程师考前培训-兰麦咨询工程师考前辅导-北京兰麦教育-全部课程-试听" },
  },
  {
    path: "/BeforeExam/curriculumVideo",
    component: () => importPage("BeforeExam/curriculumVideo"),
    meta: { title: "咨询工程师考前培训-兰麦咨询工程师考前辅导-北京兰麦教育-全部课程-试听" },
  },
  // =====备考大全 =====
  {
    path: "/BeforeExam/questionBank",
    component: () => importPage("BeforeExam/questionBank"),
    meta: { title: "咨询工程师考前培训-兰麦咨询工程师考前辅导-北京兰麦教育-在线题库" },
  },
  {
    path: "/BeforeExam/questionBankPractice",
    component: () => importPage("BeforeExam/questionBankPractice"),
    meta: { title: "咨询工程师考前培训-兰麦咨询工程师考前辅导-北京兰麦教育-在线题库练习" },
  },
  {
    path: "/BeforeExam/questionBankPracticeM",
    component: () => importPage("BeforeExam/questionBankPracticeM"),
    meta: { title: "咨询工程师考前培训-兰麦咨询工程师考前辅导-北京兰麦教育-在线题库练习" },
  },
  {
    path: "/BeforeExam/questionBankExam",
    component: () => importPage("BeforeExam/questionBankExam"),
    meta: { title: "咨询工程师考前培训-兰麦咨询工程师考前辅导-北京兰麦教育-在线题库考试列表" },
  },
  {
    path: "/BeforeExam/questionBankExamInfo",
    component: () => importPage("BeforeExam/questionBankExamInfo"),
    meta: { title: "咨询工程师考前培训-兰麦咨询工程师考前辅导-北京兰麦教育-在线题库考试" },
  },
  {
    path: "/BeforeExam/questionBankExamHand",
    component: () => importPage("BeforeExam/questionBankExamHand"),
    meta: { title: "咨询工程师考前培训-兰麦咨询工程师考前辅导-北京兰麦教育-在线题库考试交卷" },
  },
  {
    path: "/BeforeExam/questionBankViewPaper",
    component: () => importPage("BeforeExam/questionBankViewPaper"),
    meta: { title: "咨询工程师考前培训-兰麦咨询工程师考前辅导-北京兰麦教育-在线题库考查看试卷" },
  },
  {
    path: "/BeforeExam/questionBankViewMark",
    component: () => importPage("BeforeExam/questionBankViewMark"),
    meta: { title: "咨询工程师考前培训-兰麦咨询工程师考前辅导-北京兰麦教育-在线题库考查看成绩" },
  },
  // =====学习资料 =====
  {
    path: "/BeforeExam/freeData",
    component: () => importPage("BeforeExam/freeData"),
    meta: { title: "咨询工程师考前培训-兰麦咨询工程师考前辅导-北京兰麦教育-学习资料" },
  },
  // =====免费测试 =====
  {
    path: "/BeforeExam/freeTesting",
    component: () => importPage("BeforeExam/freeTesting"),
    meta: { title: "咨询工程师考前培训-兰麦咨询工程师考前辅导-北京兰麦教育-免费测试" },
  },
  // =====常见问题 =====
  {
    path: "/BeforeExam/question",
    component: () => importPage("BeforeExam/question"),
    meta: { title: "咨询工程师考前培训-兰麦咨询工程师考前辅导-北京兰麦教育-常见问题" },
  },
  // =====在线答疑 =====
  {
    path: "/BeforeExam/onlineQuestion",
    component: () => importPage("BeforeExam/onlineQuestion"),
    meta: { title: "咨询工程师考前培训-兰麦咨询工程师考前辅导-北京兰麦教育-在线答疑" },
  },
  // =====会员中心 =====
  {
    path: "/BeforeExam/memberKaoqian",
    component: () => importPage("BeforeExam/memberKaoqian"),
    meta: { title: "咨询工程师考前培训-兰麦咨询工程师考前辅导-北京兰麦教育-会员中心-学习中心-考前培训" },
  },
  {
    path: "/BeforeExam/memberCart",
    component: () => importPage("BeforeExam/memberCart"),
    meta: { title: "咨询工程师考前培训-兰麦咨询工程师考前辅导-北京兰麦教育-会员中心-购物车" },
  },
  {
    path: "/BeforeExam/memberShOrder",
    component: () => importPage("BeforeExam/memberShOrder"),
    meta: { title: "咨询工程师考前培训-兰麦咨询工程师考前辅导-北京兰麦教育-会员中心-售后订单" },
  },
  {
    path: "/BeforeExam/memberCartPay",
    component: () => importPage("BeforeExam/memberCartPay"),
    meta: { title: "咨询工程师考前培训-兰麦咨询工程师考前辅导-北京兰麦教育-会员中心-购物车支付" },
  },
  {
    path: "/BeforeExam/memberMessageK",
    component: () => importPage("BeforeExam/memberMessageK"),
    meta: { title: "咨询工程师考前培训-兰麦咨询工程师考前辅导-北京兰麦教育-会员中心-消息中心" },
  },
  {
    path: "/BeforeExam/memberMessageT",
    component: () => importPage("BeforeExam/memberMessageT"),
    meta: { title: "咨询工程师考前培训-兰麦咨询工程师考前辅导-北京兰麦教育-会员中心-消息中心" },
  },
  {
    path: "/BeforeExam/memberMessageX",
    component: () => importPage("BeforeExam/memberMessageX"),
    meta: { title: "咨询工程师考前培训-兰麦咨询工程师考前辅导-北京兰麦教育-会员中心-消息中心" },
  },
  {
    path: "/BeforeExam/memberFeedback",
    component: () => importPage("BeforeExam/memberFeedback"),
    meta: { title: "咨询工程师考前培训-兰麦咨询工程师考前辅导-北京兰麦教育-会员中心-问题反馈" },
  },
  {
    path: "/BeforeExam/questionnaire",
    component: () => importPage("BeforeExam/questionnaire"),
    meta: { title: "咨询工程师考前培训-兰麦咨询工程师考前辅导-北京兰麦教育-会员中心-调查问卷" },
  },
  {
    path: "/BeforeExam/videoOld",
    component: () => importPage("BeforeExam/video"),
    meta: { title: "咨询工程师考前培训-兰麦咨询工程师考前辅导-北京兰麦教育-我的课程-课程详情" },
  },
  {
    path: "/BeforeExam/video",
    component: () => importPage("BeforeExam/videoNew"),
    meta: { title: "咨询工程师考前培训-兰麦咨询工程师考前辅导-北京兰麦教育-我的课程-课程详情" },
  },
  {
    path: "/BeforeExam/feeVideoOld",
    component: () => importPage("BeforeExam/feeVideo"),
    meta: { title: "咨询工程师考前培训-兰麦咨询工程师考前辅导-北京兰麦教育-免费课程-课程详情" },
  },
  {
    path: "/BeforeExam/feeVideo",
    component: () => importPage("BeforeExam/feeVideoNew"),
    meta: { title: "咨询工程师考前培训-兰麦咨询工程师考前辅导-北京兰麦教育-免费课程-课程详情" },
  },
  {
    path: "/BeforeExam/aboutUs",
    component: () => importPage("BeforeExam/aboutUs"),
    meta: { title: "咨询工程师考前培训-兰麦咨询工程师考前辅导-北京兰麦教育-关于我们" },
  },
  {
    path: "/BeforeExam/contactUs",
    component: () => importPage("BeforeExam/contactUs"),
    meta: { title: "咨询工程师考前培训-兰麦咨询工程师考前辅导-北京兰麦教育-联系我们" },
  },
  {
    path: "/BeforeExam/legalNotice",
    component: () => importPage("BeforeExam/legalNotice"),
    meta: { title: "咨询工程师考前培训-兰麦咨询工程师考前辅导-北京兰麦教育-法律声明" },
  },
  //手机版页面
  {
    path: "/Wap/info",
    component: () => importPage("Wap/info"),
    meta: { title: "咨询工程师考前培训-兰麦咨询工程师考前辅导-北京兰麦教育-领取课程" },
  },
  {
    path: "/Wap/result",
    component: () => importPage("Wap/result"),
    meta: { title: "咨询工程师考前培训-兰麦咨询工程师考前辅导-北京兰麦教育" },
  },
  {
    path: "/Wap/view",
    component: () => importPage("Wap/view"),
    meta: { title: "咨询工程师考前培训-兰麦咨询工程师考前辅导-北京兰麦教育" },
  },
  {
    path: "/Wap/view.aspx",
    component: () => importPage("Wap/view.aspx"),
    meta: { title: "咨询工程师考前培训-兰麦咨询工程师考前辅导-北京兰麦教育" },
  },
  {
    path: "/Wap/code",
    component: () => importPage("Wap/code"),
    meta: { title: "咨询工程师考前培训-兰麦咨询工程师考前辅导-北京兰麦教育" },
  },
  {
    path: "/Wap/login",
    component: () => importPage("Wap/login"),
    meta: { title: "咨询工程师考前培训-兰麦咨询工程师考前辅导-北京兰麦教育" },
  },
  {
    path: "/Wap/mybook",
    component: () => importPage("Wap/mybook"),
    meta: { title: "咨询工程师考前培训-兰麦咨询工程师考前辅导-北京兰麦教育-学习中心" },
  },
  {
    path: "/Wap/qiandao",
    component: () => importPage("Wap/qiandao"),
    meta: { title: "咨询工程师考前培训-兰麦咨询工程师考前辅导-北京兰麦教育-学习签到" },
  },
  {
    path: "/Wap/qiandaoLogin",
    component: () => importPage("Wap/qiandaoLogin"),
    meta: { title: "咨询工程师考前培训-兰麦咨询工程师考前辅导-北京兰麦教育-学习签到" },
  },
 
  {
    path: "/BeforeExam/wxPay",
    component: () => importPage("BeforeExam/wxPay"),
    meta: { title: "咨询工程师考前培训-兰麦咨询工程师考前辅导-北京兰麦教育" },
  },
  {
    path: "/BeforeExam/mobileWxPay",
    component: () => importPage("BeforeExam/mobileWxPay"),
    meta: { title: "咨询工程师考前培训-兰麦咨询工程师考前辅导-北京兰麦教育" },
  },
  {
    path: "/BeforeExam/aliPay",
    component: () => importPage("BeforeExam/aliPay"),
    meta: { title: "咨询工程师考前培训-兰麦咨询工程师考前辅导-北京兰麦教育" },
  },
  {
    path: "/BeforeExam/aliPayNotify",
    component: () => importPage("BeforeExam/aliPayNotify"),
    meta: { title: "咨询工程师考前培训-兰麦咨询工程师考前辅导-北京兰麦教育" },
  },
  {
    path: "/BeforeExam/bindPhone",
    component: () => importPage("BeforeExam/bindPhone"),
    meta: { title: "咨询工程师考前培训-兰麦咨询工程师考前辅导-北京兰麦教育" },
  },
  {
    path: "/BeforeExam/wechatScanNotify",
    component: () => importPage("BeforeExam/wechatScanNotify"),
    meta: { title: "咨询工程师考前培训-兰麦咨询工程师考前辅导-北京兰麦教育" },
  },
  {
    path: "/IndustryTraining/loginPhone",
    component: () => importPage("IndustryTraining/loginPhone"), 
    meta: { title: "咨询工程师考前培训-兰麦咨询工程师考前辅导-北京兰麦教育" },
  },
  {
    path: "/BeforeExam/wxauth",
    component: () => importPage("BeforeExam/wxauth"),
    meta: { title: "咨询工程师考前培训-兰麦咨询工程师考前辅导-北京兰麦教育" },
  },
 


  //考前新增页面  
  // 热点关注
  {
    path: "/BeforeExam/focusList",
    component: () => importPage("BeforeExam/focusList"),
    meta: { title: "咨询工程师考前培训-兰麦咨询工程师考前辅导-北京兰麦教育" },
  },
  {
    path: "/BeforeExam/focusDeta",
    component: () => importPage("BeforeExam/focusDeta"),
    meta: { title: "咨询工程师考前培训-兰麦咨询工程师考前辅导-北京兰麦教育" },
  },
  {
    path: "/BeforeExam/teachers",
    component: () => importPage("BeforeExam/teachers"),
    meta: { title: "咨询工程师考前培训-兰麦咨询工程师考前辅导-北京兰麦教育" },
  },
  {
    path: "/BeforeExam/teachersDeta",
    component: () => importPage("BeforeExam/teachersDeta"),
    meta: { title: "咨询工程师考前培训-兰麦咨询工程师考前辅导-北京兰麦教育" },
  },
  {
    path: "/Wap/login",
    component: () => importPage("Wap/login"),
    meta: { title: "咨询工程师考前培训-兰麦咨询工程师考前辅导-北京兰麦教育" },
  },
  {
    path: "/Wap/loginout",
    component: () => importPage("Wap/loginout"),
    meta: { title: "咨询工程师考前培训-兰麦咨询工程师考前辅导-北京兰麦教育" },
  },
  {
    path: "/Wap/sendMsg",
    component: () => importPage("Wap/sendMsg"),
    meta: { title: "咨询工程师考前培训-兰麦咨询工程师考前辅导-北京兰麦教育" },
  },
  {
    path: "/BeforeExam/jump",
    component: () => importPage("BeforeExam/jump"),
    meta: { title: "咨询工程师考前培训-兰麦咨询工程师考前辅导-北京兰麦教育" },
  },
  {
    path: "/BeforeExam/jumpcurriculum",
    component: () => importPage("BeforeExam/jumpcurriculum"),
    meta: { title: "咨询工程师考前培训-兰麦咨询工程师考前辅导-北京兰麦教育" },
  },
  {
    path: "/BeforeExam/link",
    component: () => importPage("BeforeExam/link"),
    meta: { title: "咨询工程师考前培训-兰麦咨询工程师考前辅导-北京兰麦教育" },
  },
 
  //行业培训路由
  {
		path: "/IndustryTraining",
		redirect: "/IndustryTraining/index",
	},
	// 行业培训
	{
		path: "/IndustryTraining/index",
		component: () => importPage("IndustryTraining/index"),
		meta: { title: "北京兰麦科技有限公司" },
	},
	// 培训视频
	{
		path: "/IndustryTraining/pxvideo",
		component: () => importPage("IndustryTraining/pxvideo"),
		meta: { title: "北京兰麦科技有限公司" },
	},
	{
		path: "/IndustryTraining/videodate",
		component: () => importPage("IndustryTraining/videodate"),
		meta: { title: "北京兰麦科技有限公司" },
	},
	// 培训通知
	{
		path: "/IndustryTraining/pxnotice",
		component: () => importPage("IndustryTraining/pxnotice"),
		meta: { title: "北京兰麦科技有限公司" },
	},
	{
		path: "/IndustryTraining/noticedate",
		component: () => importPage("IndustryTraining/noticedate"),
		meta: { title: "北京兰麦科技有限公司" },
	},
	{
		path: "/IndustryTraining/noticelist",
		component: () => importPage("IndustryTraining/noticelist"),
		meta: { title: "北京兰麦科技有限公司" },
	},
	// 选课中心
	{
		path: "/IndustryTraining/course",
		component: () => importPage("IndustryTraining/course"),
		meta: { title: "北京兰麦科技有限公司" },
	},
	{
		path: "/IndustryTraining/course2",
		component: () => importPage("IndustryTraining/course2"),
		meta: { title: "北京兰麦科技有限公司" },
	},
	{
		path: "/IndustryTraining/coursedetails",
		component: () => importPage("IndustryTraining/coursedetails"),
		meta: { title: "北京兰麦科技有限公司" },
	},
	{
		path: "/IndustryTraining/courseinfo",
		component: () => importPage("IndustryTraining/courseinfo"),
		meta: { title: "北京兰麦科技有限公司" },
	},
	{
		path: "/IndustryTraining/courseinfo_new",
		component: () => importPage("IndustryTraining/courseinfo_new"),
		meta: { title: "北京兰麦科技有限公司" },
	},
	{
		path: "/IndustryTraining/courseAudition",
		component: () => importPage("IndustryTraining/courseAudition"),
		meta: { title: "北京兰麦科技有限公司" },
	},
	{
		path: "/IndustryTraining/personal_car",
		component: () => importPage("IndustryTraining/personal_car"),
		meta: { title: "北京兰麦科技有限公司" },
	},
	{
		path: "/IndustryTraining/personal_cartSettlement",
		component: () => importPage("IndustryTraining/personal_cartSettlement"),
		meta: { title: "北京兰麦科技有限公司" },
	},
	{
		path: "/IndustryTraining/videoplay",
		component: () => importPage("IndustryTraining/videoplay"),
		meta: { title: "北京兰麦科技有限公司" },
	},
	// 明星讲师
	{
		path: "/IndustryTraining/lecturer",
		component: () => importPage("IndustryTraining/lecturer"),
		meta: { title: "北京兰麦科技有限公司" },
	},
	{
		path: "/IndustryTraining/lecturerdate",
		component: () => importPage("IndustryTraining/lecturerdate"),
		meta: { title: "北京兰麦科技有限公司" },
	},
	{
		path: "/IndustryTraining/question",
		component: () => importPage("IndustryTraining/question"),
		meta: { title: "北京兰麦科技有限公司" },
	},
	// 个人中心
	{
		path: "/IndustryTraining/personal_infor",
		component: () => importPage("IndustryTraining/personal_infor"),
		meta: { title: "北京兰麦科技有限公司" },
	},
	{
		path: "/IndustryTraining/personal_course",
		component: () => importPage("IndustryTraining/personal_course"),
		meta: { title: "北京兰麦科技有限公司" },
	},
	{
		path: "/IndustryTraining/personal_settlement",
		component: () => importPage("IndustryTraining/personal_settlement"),
		meta: { title: "北京兰麦科技有限公司" },
	},
	{
		path: "/IndustryTraining/personal_order",
		component: () => importPage("IndustryTraining/personal_order"),
		meta: { title: "北京兰麦科技有限公司" },
	},
	{
		path: "/IndustryTraining/personal_invoice",
		component: () => importPage("IndustryTraining/personal_invoice"),
		meta: { title: "北京兰麦科技有限公司" },
	},
	{
		path: "/IndustryTraining/wxPay",
		component: () => importPage("IndustryTraining/wxPay"),
		meta: { title: "北京兰麦科技有限公司" },
	},
	{
		path: "/IndustryTraining/aliPay",
		component: () => importPage("IndustryTraining/aliPay"),
		meta: { title: "北京兰麦科技有限公司" },
	},
	{
		path: "/IndustryTraining/aliPayNotify",
		component: () => importPage("IndustryTraining/aliPayNotify"),
		meta: { title: "北京兰麦科技有限公司" },
	},
	{
		path: "/IndustryTraining/search",
		component: () => importPage("IndustryTraining/search"),
		meta: { title: "北京兰麦科技有限公司" },
	},
	{
		path: "/IndustryTraining/wechatScanNotify",
		component: () => importPage("IndustryTraining/wechatScanNotify"),
		meta: { title: "北京兰麦科技有限公司" },
	},
	{
		path: "/IndustryTraining/bindPhone",
		component: () => importPage("IndustryTraining/bindPhone"),
		meta: { title: "北京兰麦科技有限公司" },
	},
	{
		path: "/IndustryTraining/tcplayer",
		component: () => importPage("IndustryTraining/tcplayer"),
		meta: { title: "北京兰麦科技有限公司" },
	},
	{
		path: "/IndustryTraining/video",
		component: () => importPage("IndustryTraining/video"),
		meta: { title: "北京兰麦科技有限公司" },
	},
	{
		path: "/IndustryTraining/videoext",
		component: () => importPage("IndustryTraining/videoext"),
		meta: { title: "北京兰麦科技有限公司" },
	},
	{
		path: "/IndustryTraining/login",
		component: () => importPage("IndustryTraining/login"),
		meta: { title: "北京兰麦科技有限公司" },
	},
	{
		path: "/IndustryTraining/token",
		component: () => importPage("IndustryTraining/token"),
		meta: { title: "北京兰麦科技有限公司" },
	},
	{
		path: "/Wap/denglu",
		component: () => importPage("Wap/denglu"),
		meta: { title: "北京兰麦科技有限公司" },
	},
	{
		path: "/IndustryTraining/tcplayerext",
		component: () => importPage("IndustryTraining/tcplayerext"),
		meta: { title: "北京兰麦科技有限公司" },
	},
	{
		path: "/IndustryTraining/ccplayerext",
		component: () => importPage("IndustryTraining/ccplayerext"),
		meta: { title: "北京兰麦科技有限公司" },
	},
	{
		path: "/IndustryTraining/retrievePassword",
		component: () => importPage("IndustryTraining/retrievePassword"),
		meta: { title: "北京兰麦科技有限公司" },
	},
	{
		path: "/IndustryTraining/playvideo",
		component: () => importPage("IndustryTraining/playvideo"),
		meta: { title: "北京兰麦科技有限公司" },
	},


  {
    path: "/Web",
    redirect: "/Web/index",
  },
  {
    path: "/Web/index",
    component: () => importPage("Web/index"),
    meta: { title: "咨询工程师考前培训-兰麦咨询工程师考前辅导-北京兰麦教育" },
  },
  {
    path: "/Web/about-profile",
    component: () => importPage("Web/about-profile"),
    meta: { title: "咨询工程师考前培训-兰麦咨询工程师考前辅导-北京兰麦教育" },
  },
  {
    path: "/Web/abouttdjs-deta",
    component: () => importPage("Web/abouttdjs-deta"),
    meta: { title: "咨询工程师考前培训-兰麦咨询工程师考前辅导-北京兰麦教育" },
  },
  {
    path: "/Web/about-structure",
    component: () => importPage("Web/about-structure"),
    meta: { title: "咨询工程师考前培训-兰麦咨询工程师考前辅导-北京兰麦教育" },
  },
  {
    path: "/Web/about-recruit",
    component: () => importPage("Web/about-recruit"),
    meta: { title: "咨询工程师考前培训-兰麦咨询工程师考前辅导-北京兰麦教育" },
  },

  {
    path: "/Web/about-contactUs",
    component: () => importPage("Web/about-contactUs"),
    meta: { title: "咨询工程师考前培训-兰麦咨询工程师考前辅导-北京兰麦教育" },
  },
  {
    path: "/Web/business-layout",
    component: () => importPage("Web/business-layout"),
    meta: { title: "咨询工程师考前培训-兰麦咨询工程师考前辅导-北京兰麦教育" },
  },
  {
    path: "/Web/cooperation",
    component: () => importPage("Web/cooperation"),
    meta: { title: "咨询工程师考前培训-兰麦咨询工程师考前辅导-北京兰麦教育" },
  },
  {
    path: "/Web/news",
    component: () => importPage("Web/news"),
    meta: { title: "咨询工程师考前培训-兰麦咨询工程师考前辅导-北京兰麦教育" },
  },
  {
    path: "/Web/new-deta",
    component: () => importPage("Web/new-deta"),
    meta: { title: "咨询工程师考前培训-兰麦咨询工程师考前辅导-北京兰麦教育" },
  },
  {
    path: "/Web/serve",
    component: () => importPage("Web/serve"),
    meta: { title: "咨询工程师考前培训-兰麦咨询工程师考前辅导-北京兰麦教育" },
  },
  {
    path: "/Web/servedeta",
    component: () => importPage("Web/servedeta"),
    meta: { title: "咨询工程师考前培训-兰麦咨询工程师考前辅导-北京兰麦教育" },
  },
  {
    path: "/Web/industry-style",
    component: () => importPage("Web/industry-style"),
    meta: { title: "咨询工程师考前培训-兰麦咨询工程师考前辅导-北京兰麦教育" },
  }, 
  {
    path: "/Web/industry-styledeta",
    component: () => importPage("Web/industry-styledeta"),
    meta: { title: "咨询工程师考前培训-兰麦咨询工程师考前辅导-北京兰麦教育" },
  },
  {
    path: "/Web/legalNotice",
    component: () => importPage("Web/legalNotice"),
    meta: { title: "咨询工程师考前培训-兰麦咨询工程师考前辅导-北京兰麦教育" },
  },
  {
    path: "/Web/link",
    component: () => importPage("Web/link"),
    meta: { title: "咨询工程师考前培训-兰麦咨询工程师考前辅导-北京兰麦教育" },
  },
  {
    path: "/Web/branch",
    component: () => importPage("Web/branch"),
    meta: { title: "咨询工程师考前培训-兰麦咨询工程师考前辅导-北京兰麦教育" },
  },
  {
    path: "/Web/about-history",
    component: () => importPage("Web/about-history"),
    meta: { title: "咨询工程师考前培训-兰麦咨询工程师考前辅导-北京兰麦教育" },
  },
  {
    path: "/Web/search",
    component: () => importPage("Web/search"),
    meta: { title: "咨询工程师考前培训-兰麦咨询工程师考前辅导-北京兰麦教育" },
  },
  {
    path: "/Wap/emption",
    component: () => importPage("Wap/emption"),
    meta: { title: "咨询工程师考前培训-兰麦咨询工程师考前辅导-北京兰麦教育" },
  },
  {
    path: "/Wap/emption-details",
    component: () => importPage("Wap/emption-details"),
    meta: { title: "咨询工程师考前培训-兰麦咨询工程师考前辅导-北京兰麦教育" },
  },
  {
    path: "/IndustryTraining/ccplayer",
    component: () => importPage("IndustryTraining/ccplayer"),
    meta: { title: "咨询工程师考前培训-兰麦咨询工程师考前辅导-北京兰麦教育" },
  },
  {
    path: "/BeforeExam/ccplayer",
    component: () => importPage("BeforeExam/ccplayer"),
    meta: { title: "咨询工程师考前培训-兰麦咨询工程师考前辅导-北京兰麦教育" },
  },
  {
    path: "/BeforeExam/ccplayerext",
    component: () => importPage("BeforeExam/ccplayerext"),
    meta: { title: "咨询工程师考前培训-兰麦咨询工程师考前辅导-北京兰麦教育" },
  },
  {
    path: "/BeforeExam/tcplayer",
    component: () => importPage("BeforeExam/tcplayer"),
    meta: { title: "咨询工程师考前培训-兰麦咨询工程师考前辅导-北京兰麦教育" },
  },
  {
    path: "/BeforeExam/tcplayerext",
    component: () => importPage("BeforeExam/tcplayerext"),
    meta: { title: "咨询工程师考前培训-兰麦咨询工程师考前辅导-北京兰麦教育" },
  },
  {
    path: "/BeforeExam/playBack",
    component: () => importPage("BeforeExam/playBack"),
    meta: { title: "咨询工程师考前培训-兰麦咨询工程师考前辅导-北京兰麦教育" },
  },
  {
    path: "/BeforeExam/playBackext",
    component: () => importPage("BeforeExam/playBackext"),
    meta: { title: "咨询工程师考前培训-兰麦咨询工程师考前辅导-北京兰麦教育" },
  },
 
];

const router = createRouter({
  mode: "hash",
  history: createWebHashHistory(process.env.BASE_URL),
  //history:createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  console.log('路由守卫',to,from,window.location);
  if(window.location.host=='kaoshi.lanmaiedu.com'&&to.path=='/BeforeExam/index'){
     window.location.href='https://kaoshi.lanmaiedu.com/website';
     return
  }
  next();
})

export default router;
